export default {
    sections: [
        {
            header: 'ABOUT',
            lines: [ //{ text, lines }
                'These terms and conditions (“Terms”) govern the use of the software platform provided by BlitzItWeb Tech (“BlitzItWeb”) for the provision of cloud-based logistics and order management software (the “Service”). By subscribing, accessing or using the Service, you agree to be bound by these Terms and all applicable laws. If you do not agree to these Terms, you may not subscribe to, access or use the Service.'
            ]
        },
        {
            header: '1.  DEFINITIONS & INTERPRETATION',
            lines: [
                'The following definitions apply in these Terms:',
                '“Authorised Users” means customers or clients of the Customer who are authorised by the Customer to access the end user ordering component of the Service;',
                '“Confidential Information” means any non-public information that is disclosed by one party to the other party during the course of the business relationship between them pursuant to these Terms. This information may include, but is not limited to, trade secrets, financial information, customer lists, business plans, marketing strategies, and other proprietary information.',
                '“Customer” means the entity that has signed up for the Service;',
                '“Intellectual Property Rights” includes rights arising from intangible creations of the human mind and intellect and without limitation includes all present and future rights to:',
                'The following definitions apply in these Terms:',
                '“Authorised Users” means customers or clients of the Customer who are authorised by the Customer to access the end user ordering component of the Service;',
                '“Confidential Information” means any non-public information that is disclosed by one party to the other party during the course of the business relationship between them pursuant to these Terms. This information may include, but is not limited to, trade secrets, financial information, customer lists, business plans, marketing strategies, and other proprietary information.',
                '“Customer” means the entity that has signed up for the Service;',
                '“Intellectual Property Rights” includes rights arising from intangible creations of the human mind and intellect and without limitation includes all present and future rights to:',
                '(i)	copyright;',
                '(ii)	registered or unregistered designs, patents, trade marks;',
                '(iii)	trade, business, company or domain names;',
                '(iv)	know-how, inventions, processes, trade secrets;',
                '(v)	databases and/or source codes; and',
                '(vi)	any similar rights in any part of the world,',
                'including any application, or right to apply, for registration of, and any improvements, enhancements or modifications of, the foregoing.',
                '“Service” means the cloud-based logistics and order management software provided by BlitzItWeb;',
                '“Subscription Fee” means the periodic fee payable by the Customer for continued access to and use of the Service in the amount detailed on BlitzItWeb’s website or otherwise communicated in writing to the Customer;',
                '“User” means any individual who has been granted access to the Service or part of it by the Customer.'
            ]
        },
        {
            header: '2.  GRANT OF SUBSCRIPTION AND LICENSE',
            lines: [
                '2.1	Subject to all limitations and restrictions contained in these Terms, BlitzItWeb grants to the Customer a non-exclusive and non-transferable right to access and use the Service (the “Licence”).',
                '2.2	The Customer has a limited right to use the Service solely for the Customer’s internal business purposes. The Customer shall not allow any website that is not fully owned by the Customer to frame, syndicate, distribute, replicate, or copy any portion of the Customer’s web site that provides direct or indirect access to the Service. Unless otherwise expressly permitted the Customer shall not permit any subsidiaries, affiliated companies, or third parties to access the Service.',
                '2.3	The Customer acknowledges that the Licence:',
                '(a)	permits the Customer to use the Service in accordance with the Service’s normal operating procedures; and',
                '(b)	permits the Customer to provide access and use of the Service to Authorised Users as enabled within the Service.'
            ]
        },
        {
            header: '3.  AVAILABILITY OF SERVICE',
            lines: [
                'BlitzItWeb will use reasonable endeavours to make the Service available at all times. The Customer acknowledges that from time to time the Service will be unavailable for minimal periods of time due to maintenance. Such periods of time are not generally expected to exceed a few seconds. Scheduled maintenance will as far as practicable be conducted outside normal working hours.'
            ]
        },
        {
            header: '4.  TERM AND TERMINATION',
            lines: [
                '4.1	The agreement constituted under these Terms (the “Agreement”) commences when the Customer first creates an account, and continues until terminated.',
                '4.2	Termination',
                '(a)	The Customer may terminate this Agreement by giving BlitzItWeb notice via the website for the Service. Such termination will take effect at the expiry of the then current billing cycle.',
                '(b)	BlitzItWeb may terminate this Agreement by giving at least 60 days’ notice to the Customer if BlitzItWeb determines that it will no longer offer the Service.',
                '(c)	BlitzItWeb may terminate this Agreement immediately by giving written notice to the Customer where:',
                '(i)	an Insolvency Event occurs in relation to the Customer;',
                '(ii)	the Customer commits a breach of this Agreement not capable of remedy; or',
                '(iii)	breaches clause 5 (Licence Restrictions) or clause 6 (Prohibited Use).',
                '(d)	BlitzItWeb may suspend provision of the Service to any Customer whose subscription is not current without notice to the Customer.',
                '(e)	Termination of this Agreement will not limit either party from pursuing other remedies available to it, including injunctive relief.'
            ]
        },
        {
            header: '5.  RESTRICTIONS ON LICENSE',
            lines: [
                'The Customer must not access or use the Service except as permitted by these Terms and Conditions and may not do or authorise the commission of any act that would or might invalidate or be inconsistent with BlitzItWeb’s Intellectual Property Rights in the Service. Without limiting the foregoing, the Customer agrees and acknowledges that it must not and will not permit any person to:',
                '(a)	resell, assign, transfer, distribute or provide others with access to the Service;',
                '(b)	copy, alter, modify, create derivative works from, reproduce, resell, transfer to a third party, reverse assemble, reverse engineer, reverse compile or enhance the Service (except as expressly permitted by the Copyright Act 1968 (Cth)); or',
                '(c)	alter, remove or tamper with any trademarks, any patent or copyright notices, or any confidentiality legend or notice, or any numbers, or other means of identification used on or in relation to the Service.',
                '5.2	The Customer must not use the Service in any way which is in breach of any statute, regulation, law or legal right of any person within Australia.'
            ]
        },
        {
            header: '6.  PROHIBITED USE',
            lines: [
                '6.1	The Customer acknowledges and agrees that these Terms incorporate the terms of any acceptable use policy as set out on BlitzItWeb’s website or as provided to the Customer from time to time.',
                '6.2	The Customer acknowledges and agrees that it must not, and will ensure each Authorised User does not:',
                '(a)	use the Service to violate any legal rights of any person, the Customer or other entity in any jurisdiction;',
                '(b)	use the Service in relation to crimes such as theft and fraud;',
                '(c)	use the Service in breach of laws relating to the protection of copyright, trade secrets, patents or other intellectual property and laws relating to spam or privacy;',
                '(d)	make any unauthorised copy of any copyrighted material owned or licenced by BlitzItWeb;',
                '(e)	introduce malicious programs into the Service (e.g. viruses, worms, trojan horses, e-mail bombs);',
                '(f)	reveal the Customer’s account password to others or allow use of the Customer’s account by any person who is not the Customer’s personnel or Authorised Users;',
                '(g)	use the Service to carry out security breaches or disruptions of a network. Security breaches include, but are not limited to, accessing data of which the Customer is not an intended recipient or logging into a server or account that the Customer is not expressly authorised to access or corrupting any data. For the purposes of this paragraph, “security breaches” includes (but is not limited to) network sniffing/monitoring, ping floods, packet spoofing, denial of service, and forged routing information for malicious purposes;',
                '(h)	use any program/script/command, or sending messages of any kind, with the intent to interfere with, or disable, any persons’ use of the Service;',
                '(i)	send any unsolicited email messages through or to users of the Service in breach of the Spam Act 2003 (Cth) or to send any form of harassment via email, or any other form of messaging, whether through language, frequency, or size of messages; use the Service in breach of any person’s privacy (such as by way of identity theft or “phishing”) is strictly prohibited; and',
                '(j)	use the Service to circumvent user authentication or security of any of the Customer’s hosts, networks or accounts or those of the Customer’s customers or suppliers.'
            ]
        },
        {
            header: '7.  CUSTOMER OBLIGATIONS',
            lines: [
                '7.1	The Customer is responsible for its use of the Service and must ensure that no person uses the Service:',
                '(a)	to break any law or infringe any person’s rights including but not limited to Intellectual Property Rights;',
                '(b)	to transmit, publish or communicate material that is defamatory, offensive, abusive, indecent, menacing or unwanted; or',
                '(c)	in any way that damages, interferes with or interrupts the supply of the Service.',
                '7.2	The Customer acknowledges and agrees that:',
                '(a)	it is responsible for all users using the Service including its personnel and any Authorised Users;',
                '(b)	its use of the Service will be at its own risk;',
                '(c)	it is responsible for maintaining the security of its account, user logins, and user passwords. BlitzItWeb will not be liable for any loss or damage from the Customer’s failure to comply with this security obligation;',
                '(d)	BlitzItWeb may alter or update the Customer’s account logins and passwords and the logins and passwords of any Authorised Users at any time throughout the Term;',
                '(e)	the Customer is responsible for all content posted and activity that occurs under their account. This includes content posted by others who have logins or accounts associated with the Customer’s account;',
                '(f)	the technical processing and transmission of the Service, including the Customer’s content, may be transferred unencrypted and involve (i) transmissions over various networks; and (ii) changes to conform and adapt to technical requirements of connecting networks or devices; and',
                '(g)	BlitzItWeb may pursue any available equitable or other remedy against the Customer as a result of a breach by the Customer of any provision of these Terms.'
            ]
        },
        {
            header: '8.  PAYMENT',
            lines: [
                '8.1	The Customer must pay BlitzItWeb:',
                '(a)	the Subscription Fee; and',
                { words: [
                    '(b)	"Per use" fees at the rates indicated ',
                    { text: 'here', link: 'https://www.blitzitweb.com.au/#pricing' },
                    '.'
                ]},
                'at the times and in the manner specified by BlitzItWeb as a condition of accessing the Service. If no other time or manner of payment is specified from time to time, Subscription and Per Use fees are invoiced to the Customer at the end of each calendar month and are payable within 7 days of the date of invoice.',
                '8.2	If any periodic Subscription Fee has not been paid when due BlitzItWeb may (at its absolute discretion):',
                '(a)	immediately cease providing the Service, and recover as a debt due and immediately payable from the Customer its Additional Costs of doing so;',
                '(b)	charge interest at a rate equal to the Reserve Bank of Australia’s cash rate from time to time plus 8% per annum, calculated daily and compounding monthly, on any such amounts unpaid after the due date;',
                '(c)	engage debt collection services and/or commence legal proceedings in relation to any such amounts; and/or',
                '(d)	report the Customer to any independent credit data agencies.'
            ]
        },
        {
            header: '9.  OWNERSHIP, RESERVATION OF RIGHTS & INTELLECTUAL PROPERTY',
            lines: [
                '9.1	BlitzItWeb owns all proprietary rights, title, and interest in and to the Service, including all software, data, information, and any Customer configuration documentation. The Customer shall not copy, reverse engineer, or otherwise use the Service in any manner that would infringe BlitzItWeb’s proprietary rights.',
                '9.2	BlitzItWeb reserves the right to change or remove features of the Service from time to time. Where there is any material alteration to the Service in accordance with this clause, BlitzItWeb will provide the Customer with 20 Business Days’ notice and the Customer agrees that any material alteration is at BlitzItWeb’s discretion.',
                '9.3	The Parties agree that BlitzItWeb:',
                '(a)	will supply the Service on a non-exclusive basis;',
                '(b)	does not make any warranty or representation as to the ability of the facilities or services of any third-party suppliers; and',
                '(c)	is not liable for any failure in, fault with or degradation of the Service if that failure, fault or degradation is attributable to or caused by any failure of the Customer Environment or the facilities or services of any third party.',
                '9.4	BlitzItWeb reserves the right to refuse any request in relation to the Service that it deems inappropriate, unreasonable, or illegal.',
                '9.5	A party’s ownership of, or any right, title or interest in, any Intellectual Property Rights in an item which exists prior to commencement of this Agreement (“Pre-Existing Material”) will not be altered, transferred or assigned by virtue of these Terms.',
                '9.6	The Customer grants to BlitzItWeb a non-exclusive, royalty free, non-transferable and revocable licence to use any of the Customer’s Intellectual Property Rights including any Pre-Existing Material as reasonably required for BlitzItWeb to provide the Service to the Customer.',
                '9.7	BlitzItWeb does not screen content uploaded onto the Service, but reserves the right (but not the obligation) in its sole discretion to refuse or remove any content that is available via the Service that BlitzItWeb deems inappropriate, illegal, offensive, threatening, libellous, defamatory, pornographic, obscene, or otherwise objectionable or violate any party’s Intellectual Property Rights or this Agreement.',
                '9.8	BlitzItWeb retains the discretion (without obligation) to terminate a Customer’s access to and use of the Service if BlitzItWeb determines that Customer or any of its Authorised Users are a repeat infringer of the Intellectual Property Rights of BlitzItWeb or of any third party.'
            ]
        },
        {
            header: '10.  PRIVACY',
            lines: [
                '10.1	Each party shall comply with its obligations under the Privacy Act 1988 (Cth) and any other applicable legislation or privacy guidelines as amended from time to time in relation to personal information collected, used or disclosed by that party or its personnel in connection with the Service and this Agreement.',
                { words: [
                    '10.2	Details on how BlitzItWeb complies with the Privacy Act 1988 (Cth) and how it collects, discloses, holds or uses personal information is available in BlitzItWeb’s Privacy Policy as set out on BlitzItWeb’s website ',
                    { text: 'here', link: 'https://www.blitzitweb.com.au/privacy-policy' },
                    ' or as otherwise provided to the Customer. BlitzItWeb reserves the right to amend its Privacy Policy from time to time by notice to the Customer.'
                ]}
            ]
        },
        {
            header: '11.  DATA AND SECURITY',
            lines: [
                '11.1	The Customer grants BlitzItWeb a limited licence to copy, transmit, store and back-up or otherwise access, use or make reference to any Intellectual Property Rights in the Data:',
                '(a)	to supply the Service including to enable the Customer, its personnel and any Authorised Users to access and use the Service;',
                '(b)	to do analysis for the purposes of predictive safety analytics, industry guideline production and other construction safety-related uses, provide such Data is re-identified;',
                '(c)	for diagnostic purposes;',
                '(d)	to test, enhance and otherwise modify the Service whether requested by the Customer or not;',
                '(e)	to develop other services; and',
                '(f)	as reasonably required for the performance of BlitzItWeb’s obligations under these Terms.',
                '11.2	The Customer acknowledges and agrees that:',
                '(a)	any collation, conversion and analysis of Data performed as part of the Service whether by the Service or otherwise is likely to be subject to human input and machine errors, omissions, delays and losses including but not limited to any loss of Data. BlitzItWeb is not liable for any such errors, omissions, delays or losses. The Customer acknowledges and agrees it is responsible for adopting reasonable measures to limit the impact of such loss or error;',
                '(b)	BlitzItWeb may relocate the Data to another jurisdiction. In each case, BlitzItWeb will give the Customer 15 Business Days’ notice and use all reasonable endeavours to minimise the effect of such change on the Customer’s access and use of the Service;',
                '(c)	BlitzItWeb is not responsible for any corruption or loss of any Data if such corruption or loss is due to an act or omission by the Customer, its personnel, its Related Bodies Corporate or any Authorised Users; and',
                '(d)	BlitzItWeb is not responsible for the integrity or existence of any Data on the Customer’s Environment, network or any device controlled by the Customer or its personnel.',
                '(e)	The Customer grants to BlitzItWeb a non-exclusive, royalty-free license to access, use, reproduce, modify, perform, display and distribute the Customer’s data as is reasonable or necessary for BlitzItWeb to perform or provide the Service.'
            ]
        },
        {
            header: '12.  CONFIDENTIALITY',
            lines: [
                '12.1	BlitzItWeb must maintain commercially reasonable security measures to protect all Confidential Information in its possession or control, or in the possession or control of its personnel, from unauthorised access, use, copying or disclosure.',
                '12.2	Subject to clause 12.3, each party must (and must ensure that its personnel do):',
                '(a)	keep confidential; and',
                '(b)	not use or permit any unauthorised use of,',
                'all Confidential Information.',
                '12.3	Clause 12.2 does not apply where:',
                '(a)	the information is in, or comes into, the public domain (other than by a breach of this clause 12 by the relevant party);',
                '(b)	the relevant party has the prior written consent of the party that disclosed the Confidential Information;',
                '(c)	the disclosure is required by law;',
                '(d)	the disclosure is required in order to comply with this Agreement, provided that the party disclosing the Confidential Information ensures the recipient complies with the terms of this clause 12; and',
                '(e)	the disclosure is to a professional adviser in order to obtain advice in relation to matters arising in connection with this Agreement and provided that the party disclosing the Confidential Information ensures the adviser complies with the terms of this clause 12.',
                '12.4	Each party acknowledges and agrees that monetary damages may not be an adequate remedy for a breach of this clause 12.  A party is entitled to seek an injunction, or any other remedy available at law or in equity, at its discretion, to protect itself from a breach (or continuing breach) of this clause 12.',
                '12.5	This clause 12 will survive the termination or expiry of this Agreement.'
            ]
        },
        {
            header: '13.  WARRANTIES',
            lines: [
                '13.1	General',
                'Each party represents and warrants to each other party:',
                '(a)	it has full legal capacity and power to enter into this Agreement, to perform its obligations under this Agreement to carry out the transactions contemplated by this Agreement, to own its property and assets and to carry on its business;',
                '(b)	no Insolvency Event has occurred in respect of it;',
                '(c)	this Agreement constitutes legal, valid and binding obligations, enforceable in accordance with its terms; and',
                '(d)	the execution and performance by it of this Agreement and each transaction contemplated by it does not conflict with any law, order, judgment, rule or regulation applicable to it or any document binding on it.',
                '13.2	BlitzItWeb Warranties',
                '(a)	BlitzItWeb warrants that the Service will perform in accordance with its specifications. BlitzItWeb does not make any other representations or warranties of any kind, express or implied, including without limitation, any implied warranties of merchantability or fitness for a particular purpose.',
                '(b)	BlitzItWeb warrants that to the best of its knowledge the Service does not infringe the Intellectual Property Rights of any third party and there are no actual or threatened proceedings for any intellectual property infringements in relation to the Service.',
                '(c)	BlitzItWeb does not warrant that the Service will be error-free or will operate without interruption or that, except as set out in this Agreement, the Service will be performed in the manner intended by the Customer or the Service will meet the requirements of the Customer.',
                '13.3	Repetition',
                'Each party makes the representations and warranties in clause 13.1 on each day on which it is a party.'
            ]
        },
        {
            header: '14.  INDEMNITY & LIMITATION OF LIABILITY',
            lines: [
                '14.1	BlitzItWeb is liable for the acts and omissions of all its personnel as if they were done by BlitzItWeb.',
                '14.2	Despite anything to the contrary (but subject to clause 14.3), to the maximum extent permitted by the law:',
                '(a)	BlitzItWeb’s maximum aggregate liability arising from or in connection with this Agreement (including the Service or the subject matter of this Agreement) will be limited to, and must not exceed, in any calendar year, the total amount of Fees paid by the Customer to BlitzItWeb in the prior calendar year; and',
                '(b)	BlitzItWeb will not be liable to the Customer for: ',
                '(i)	any loss of profit (including anticipated profit), loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings (including anticipated savings), loss of reputation, loss of use and/or loss or corruption of data );',
                '(ii)	lack of access to or use of or inability to access or use the Service;',
                '(iii)	any conduct or content of any third party on the Service;',
                '(iv)	any content obtained from the Service; nor',
                '(v)	unauthorised access, use or alteration of your transmissions or content,',
                'whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.',
                '14.3	Clause 14.2 will not apply to BlitzItWeb’s liability to the Customer under this Agreement for any liability which cannot be excluded or limited by applicable law, including:',
                '(a)	fraud or criminal conduct; or',
                '(b)	death or personal injury.',
                '14.4	Despite anything to the contrary, to the maximum extent permitted by the law, BlitzItWeb will have no liability, and the Customer releases and discharges BlitzItWeb from all liability, arising from or in connection with any:  ',
                '(a)	failure or delay in providing the Service;',
                '(b)	breach of this Agreement; and/or',
                '(c)	misuse of the Service,',
                'where caused or contributed to by any:',
                '(a)	Force Majeure Event;',
                '(b)	a fault or defect in any item of the Customer Environment; and/or',
                '(c)	act or omission of the Customer or its personnel.',
                '14.5	BlitzItWeb uses third-party providers to host the Service. BlitzItWeb will not be liable for any interruption to the Service, unavailability or outage, or any interruption, unavailability or outage of the Customer’s systems, caused by any such third-party provider.',
                '14.6	The Customer agrees that, to the maximum extent permitted by the law, this Agreement excludes all terms, conditions and warranties implied by statute, in fact or on any other basis, except to the extent such terms, conditions and warranties are fully expressed in this Agreement.',
                '14.7	The Customer agrees to indemnify, and hold harmless, BlitzItWeb against all Liabilities and Claims arising out of or in connection with:',
                '(a)	any and all unauthorised use of the Service;',
                '(b)	Authorised Users access or use of the Service;',
                '(c)	injury to or death of any person caused by any act or omission by or on behalf of the Customer or its personnel; and',
                '(d)	damage to any real or tangible property caused by any act or omission by or on behalf of the Customer or its personnel.',
                '14.8	This Clause 14 will survive the termination or expiry of this Agreement.'
            ]
        },
        {
            header: '15.  GST',
            lines: [
                '15.1	Taxable supply',
                'If GST is payable on any supply made under this Agreement, the recipient of the supply must pay an amount equal to the GST payable on the supply. That amount must be paid at the same time that the consideration is to be provided under this Agreement, and must be paid in addition to the consideration expressed elsewhere in this Agreement, unless it is expressed to be inclusive of GST. The recipient is not required to pay any GST until the supplier issues a tax invoice for the supply.',
                '15.2	Adjustment events',
                'If an adjustment event arises in respect of any supply made under this Agreement, a corresponding adjustment must be made between the supplier and the recipient in respect of any amount paid by the recipient under this clause, an adjustment note issued (if required), and any payments to give effect to the adjustment must be made.',
                '15.3	Payments',
                'If the recipient is required under this Agreement to pay for or reimburse an expense or outgoing of the supplier, or is required to make a payment under an indemnity in respect of an expense or outgoing of the supplier, the amount to be paid by the recipient is to be reduced by the amount of any input tax credit in respect of that expense or outgoing that the supplier is entitled to.',
                '15.4	GST terminology',
                'The terms “adjustment event”, “consideration”, “GST”, “input tax credit”, “recipient”, “supplier”, “supply”, “taxable supply” and “tax invoice” each has the meaning given in the A New Tax System (Goods and Services Tax) Act 1999 (Cth).',
                '15.5	This clause 15 will survive the termination or expiry of this Agreement.'
            ]
        },
        {
            header: '16.  NOTICES',
            lines: [
                '16.1	A notice or other communication given under these Terms must be:',
                '(a)	in writing, in English and signed by the sender; and',
                '(b)	addressed and delivered to the intended recipient by hand, prepaid post or email in accordance with the notice details last notified by the recipient to the Parties.',
                '16.2	The Customer’s notice details are set out in the Customer’s account. BlitzItWeb’s notice details are set out on the Website. Either party may change its notice details by written notice to the other, which, for the Customer, is by updating their Account, and for BlitzItWeb, is by updating the Website.',
                '16.3	A notice or communication is taken as having been given:',
                '(a)	when left at a party’s current address for notices;',
                '(b)	if mailed, on the third Business Day after posting (if delivered to an address within the same country) or on the tenth Business Day after posting (if delivered to an address within another country); or ',
                '(c)	if sent by email, if sent before 5pm on a Business Day in the place of receipt then on the Business Day when it is sent, otherwise on the following Business Day.'
            ]
        },
        {
            header: '17.  FORCE MAJEURE',
            lines: [
                'If performance of this Agreement or any obligation under this Agreement is prevented, restricted or interfered with by reasons of Force Majeure and the affected party unable to carry out its obligations gives the other party prompt written notice of such event, then the obligations of the affected party invoking this provision shall be suspended to the extent necessary by such event. The affected party shall use reasonable efforts under the circumstances to remove such prevention, restriction or interference or to limit the impact of the event on its performance and must continue to perform with reasonable dispatch when the Force Majeure is removed.'
            ]
        },
        {
            header: '18.  WAIVER',
            lines: [
                'Any failure or delay by a party in exercising a power or right (either wholly or partially) in relation to this Agreement does not operate as a waiver or prevent that party from exercising that power or right or any other power or right. A waiver must be in writing.'
            ]
        },
        {
            header: '19.  ASSIGNMENT',
            lines: [
                'No party may assign, transfer or otherwise deal with all or any of its rights or obligations under this Agreement without the prior written consent of the other Parties. Any purported dealing in breach of this clause 19 is of no force or effect. '
            ]
        },
        {
            header: '20.  RELATIONSHIP OF PARTIES',
            lines: [
                '(a)	This Agreement is not intended to create a partnership, joint venture or agency relationship between the Parties.',
                '(b)	Nothing in this Agreement gives a party authority to bind any other party in any way.'
            ]
        },
        {
            header: '21.  LINKS TO THIRD PARTIES',
            lines: [
                'The Service may contain links to third-party web sites or services that are not owned or controlled by BlitzItWeb. BlitzItWeb has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. The Customer further acknowledges and agree that BlitzItWeb shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.'
            ]
        },
        {
            header: '22.  SEVERANCE',
            lines: [
                '22.1	If a provision of this Agreement is held to be void, invalid, illegal or unenforceable, that provision must be read down as narrowly as necessary to allow it to be valid or enforceable.',
                '22.2	If it is not possible to read down a provision (in whole or in part), that provision (or that part of that provision) is severed from this Agreement without affecting the validity or enforceability of the remainder of that provision or the other provisions in this Agreement.'
            ]
        },
        {
            header: '23.  ENTIRE AGREEMENT',
            lines: [
                'The Agreement contains the entire understanding between the Parties, and supersedes all previous discussions, communications, negotiations, understandings, representations, warranties, commitments and agreements, in respect of its subject matter.'
            ]
        },
        {
            header: '24.  AMENDMENT',
            lines: [
                'These Terms may be amended by BlitzItWeb giving written notice to the Customer. The Customer’s continued subscription to the Service is taken as agreement to the amended Terms.'
            ]
        },
        {
            header: '25.  GOVERNING LAW AND JURISDICTION',
            lines: [
                'These Terms are governed by the laws of Victoria, Australia. Each party irrevocably and unconditionally submits to the exclusive jurisdiction of the courts operating in Victoria and any courts entitled to hear appeals from those courts and waives any right to object to proceedings being brought in those courts.'
            ]
        }
    ]
}